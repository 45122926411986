
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        









.default-template {
  @extend %h-wrapper;

  margin-top: $spacing * 2;
  padding: 0 $spacing;

  @include mq(m) {
    margin-top: $spacing * 4;
    padding: 0 $spacing * 2;
  }

  @include mq(xl) {
    margin-top: $spacing * 6;
  }
}

.default-template__title {
  color: $c-gray-light;
  font-size: 2.6rem;
  font-weight: 400;
  line-height: 1.15;
  text-align: center;

  @include mq(m) {
    font-size: 3.8vw;
  }
}
