
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        































.wrapper {
  width: 100%;
  padding: 0 20px;
  max-width: 1600px;

  @include mq(m) {
    padding: 0 40px;
  }
}

h1 {
  text-align: center;
  font-weight: 400;
  @include fluid(
    font-size,
    (
      xs: 28px,
      xl: 94px,
    )
  );
  @include fluid(
    margin-top,
    (
      xs: 30px,
      xl: 140px,
    )
  );
  @include fluid(
    margin-bottom,
    (
      xs: 30px,
      xl: 140px,
    )
  );
}

hr {
  border: 1px solid #f0f0f0;
}

.pagination {
  ol {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {
    position: relative;
    margin: 0 5px;
    color: #979797;
  }

  li.current {
    color: #000;

    &::before {
      display: block;
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      bottom: 1px;
      height: 1px;
      background: $c-black;
    }
  }
}

@include mq($until: s) {
  .list > * + * {
    @include fluid(
      margin-top,
      (
        xs: 60px,
        m: 100px,
      )
    );
  }
}

@include mq(s) {
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .list > * {
    flex-basis: 46%;
    @include fluid(
      margin-bottom,
      (
        s: 100px,
        xl: 150px,
      )
    );
  }
}
